var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Additional info")]),
      _c(
        "gov-table",
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Average wait time")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.waitTime))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Is this " + _vm._s(_vm.service.type) + " free?")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.isFree))])
                ],
                1
              ),
              _vm.appCqcLocationActive
                ? _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { scope: "row", top: "" } },
                        [_vm._v("CQC Location ID")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.cqc_location_id || "-"))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Testimonial")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.service.testimonial || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Video URL")
                  ]),
                  _c("gov-table-cell", { attrs: { break: "" } }, [
                    _vm._v(_vm._s(_vm.service.video_embed || "-"))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }